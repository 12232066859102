import React from "react"
import {useSelector} from "react-redux"
import {ButtonToolbar, Stack} from "rsuite"
import {selectCanClear, selectCanDialogBooking, selectContext} from "../store/entries-slice"
import EditButton from "../entry/edit-button"
import ExcelDownload from "./toolbar/excel-download"
import ClearButton from "./toolbar/clear-button"
import OrganizationSelect from "./toolbar/organization-select"
import PeriodSelect from "./toolbar/period-select"
import StatesSelect from "./toolbar/states-select"
import DateRange from "./toolbar/date-range"
import ToggleCombinedCrDr from "./toolbar/toggle-combined-cr-dr"
import Searchbox from "./toolbar/searchbox"

export default function Toolbar() {
    const context = useSelector(selectContext)
    const canDialogBooking = useSelector(selectCanDialogBooking)
    const canClear = useSelector(selectCanClear)

    return (
        <Stack spacing={10} childrenRenderMode="clone" alignItems="center" style={{marginBottom: ".5rem"}}>
            <Stack.Item>{"journal" === context && <OrganizationSelect/>}</Stack.Item>
            <Stack.Item>{"stack" !== context && <PeriodSelect/>}</Stack.Item>
            <Stack.Item>{"journal" !== context && <StatesSelect/>}</Stack.Item>
            <Stack.Item><ToggleCombinedCrDr/></Stack.Item>
            <Stack.Item basis="30rem" grow={1}><DateRange/></Stack.Item>
            <Stack.Item grow={3}></Stack.Item>

            <Stack.Item>
                <ButtonToolbar size="xs">
                    {canClear && <><ClearButton/>{" "}</>}

                    {("stack" === context || canDialogBooking) && (
                        <EditButton
                            size="xs"
                            icon="plus"
                            appearance="default"
                            label={"stack" === context ? "Hinzufügen" : "Dialogbuchung"}
                        />
                    )}

                    <ExcelDownload/>
                </ButtonToolbar>
            </Stack.Item>

            <Stack.Item basis="20rem" grow={2}>
                <Searchbox/>
            </Stack.Item>
        </Stack>
    )
}

